import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

import logo from 'images/logo.png';

const styles = theme => ({
  root: {
    width: '100vw',
    height: '100vh',
    overflow: 'hidden',
    display: 'grid',
    gridTemplateColumns: '100%',
    gridTemplateRows: '100%',
    placeItems: 'center',
    background: theme.palette.grey[300],
  },
  card: {
    width: '400px',
    maxWidth: '95%',
    height: 'auto',
  },
  imageContainer: {
    height: '145px',
    width: '100%',
    backgroundColor: '#2eaba4',
    display: 'grid',
    placeItems: 'center',
  },
  image: {
    width: '100%',
  },
  textCenter: {
    textAlign: 'center',
  },
  textJustify: {
    textAlign: 'justify',
  },
});

const App = ({ classes }) => (
  <div className={classes.root}>
    <Card className={classes.card}>
      <div className={classes.imageContainer}>
        <img src={logo} className={classes.image} alt="MyCast&Crew Logo" />
      </div>
      <CardContent>
        <Typography component="p" className={classes.textCenter}>
          Thank you for visiting MyCast&Crew Portal.
        </Typography>
        <br />
        <Typography component="p" className={classes.textJustify}>
          We are conducting maintenance on the MyCast&Crew portal and will be
          unavailable during this maintenance period. You will be able to access
          the MyCast&Crew portal after the maintenance is complete.
        </Typography>
        <br />
        <Typography component="p" className={classes.textJustify}>
          Please contact Product Support team at{' '}
          <a class="link" href="mailto:employeehelpdesk@castandcrew.com">
            employeehelpdesk@castandcrew.com
          </a>{' '}
          should you require assistance during this maintenance period. We
          appreciate your patience and continued support of MyCast&Crew.
        </Typography>
      </CardContent>
    </Card>
  </div>
);

export default withStyles(styles)(App);
